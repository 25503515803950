import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import "./header.css"

import Logo from "./../assets/logo/logo.svg"
import ApiMonitoringIcon from "./../assets/icons/svg/api-monitoring.svg"
import SSLMonitoringIcon from "./../assets/icons/svg/lock.svg"
import StatusPageIcon from "./../assets/icons/svg/status-page.svg"
import IncidentIcon from "./../assets/icons/svg/flash.svg"
// import { OutboundLink } from "gatsby-plugin-google-gtag"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import OpenMobileMenuIcon from "./../assets/icons/svg/menu_white.svg"

const Header = ({ size, position, currentPage, navParams }) => {
  const [isDisplayedDropdownProducts, setDisplayDropdownProducts] =
    useState(true)
   const [isDisplayedDropdownIndustry, setDisplayDropdownIndustry] = useState(false)
  const [isDisplayedMobileMenu, setDisplayMobileMenu] = useState(false)

  return (
      <nav
        className={`navbar ${currentPage} ${
          typeof navParams?.textColor !== "undefined" &&
          navParams?.textColor === "white"
            ? "navbar-white-text-color"
            : ""
        } ${size === "full" ? "full-width" : ""} ${
          position === "fixed" ? "fixed" : ""
        }`}
      >
        <div className="container">
          <div className="navbar-brand">
            <span
              className="mobile-button"
              onClick={() => setDisplayMobileMenu(!isDisplayedMobileMenu)}
            >
              <OpenMobileMenuIcon />
            </span>
            <Link to="/">
              <Logo />
            </Link>
            <div className="navbar-item mb-navbar-item btn-framed">
              <OutboundLink
                className="nav-link dropdown-toggle"
                eventCategory="Launch the Odown App"
                eventAction="Click"
                eventLabel="Goto signup"
                href="https://app.odown.io/signup"
              >
                Get started Free<span className="smooth-arrow">→</span>
              </OutboundLink>
            </div>
          </div>
          <div
            className={`navbar-menu ${
              isDisplayedMobileMenu === true ? "active" : ""
            }`}
          >
            <button
              onClick={() => setDisplayMobileMenu(false)}
              type="button"
              className="mb-close-btn opacity-100"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" className="text-white">
                ×
              </span>
            </button>
            <ul className="navbar-items">
              <li
                className="navbar-item dropdown-toggle"
                onClick={() =>
                  setDisplayDropdownProducts(!isDisplayedDropdownProducts)
                }
              >
                <div className="navbar-link no-link">Products</div>
                <div
                  className={`dropdown-content ${
                    isDisplayedDropdownProducts === true ? "mb-show" : ""
                  }`}
                >
                  <ul>
                    <li>
                      <Link to={`/product/website-monitoring`}>
                        <span className="product-name p-api_monitoring">
                          <ApiMonitoringIcon /> Website Monitoring
                        </span>
                        <span className="product-description">
                          Real-Time Alerts For Downtime, Errors & Performance
                          Metrics
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={`/product/ssl-monitoring`}>
                        <span className="product-name p-ssl_monitoring">
                          <SSLMonitoringIcon />
                          SSL Monitoring
                        </span>
                        <span className="product-description">
                          Always stay secure with our SSL monitoring
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={`/product/status-page`}>
                        <span className="product-name p-status_page">
                          <StatusPageIcon />
                          Status Page
                        </span>
                        <span className="product-description">
                          Create Beautiful, Simple Status Pages For Your App -
                          No Coding Required!
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={`/product/incident-management`}>
                        <span className="product-name p-incident_management">
                          <IncidentIcon />
                          Incident Management
                        </span>
                        <span className="product-description">
                          Manage your incident response with ease.
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li 
  className={`navbar-item dropdown-toggle ${isDisplayedDropdownIndustry ? "open" : ""}`}
  onClick={() => setDisplayDropdownIndustry(!isDisplayedDropdownIndustry)}
>
  <div className="navbar-link no-link">Use Cases</div>
  <div className={`dropdown-content ${isDisplayedDropdownIndustry ? "mb-show" : ""}`}>
    <ul>
      <li>
        <Link to={`/industry/saas`}>
          <span className="industry-saas">SaaS</span>
        </Link>
      </li>
      <li>
        <Link to={`/industry/ecommerce`}>
          <span className="industry_ecommerce">E-commerce</span>
        </Link>
      </li>
      <li>
        <Link to={`/industry/agencies`}>
          <span className="industry_agencies">Agencies</span>
        </Link>
      </li>
    </ul>
  </div>
</li>
<li className="navbar-item">
                <Link className="nav-link dropdown-toggle" to="/pricing">
                  Pricing
                </Link>
              </li>
              <li className="navbar-item">
                <Link className="nav-link dropdown-toggle" to="/integrations">
                  Integrations
                </Link>
              </li>
              <li className="navbar-item">
                <Link className="nav-link dropdown-toggle" to="/docs">
                  Docs
                </Link>
              </li>
              <li className="navbar-item">
                <OutboundLink
                  className="nav-link dropdown-toggle"
                  eventCategory="Launch the Odown App"
                  eventAction="Click"
                  eventLabel="Goto signin"
                  href="https://app.odown.io/signin"
                >
                  Sign in
                </OutboundLink>
              </li>
              <li className="navbar-item btn-framed">
                <OutboundLink
                  className="nav-link dropdown-toggle"
                  eventCategory="Launch the Odown App"
                  eventAction="Click"
                  eventLabel="Goto signup"
                  href="https://app.odown.io/signup"
                >
                  Get started Free<span className="smooth-arrow">→</span>
                </OutboundLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  )
}

Header.propTypes = {
  size: PropTypes.string,
  position: PropTypes.string,
}

Header.defaultProps = {
  size: `boxed`,
  position: `absolute`,
}

export default Header
