/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
*/

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, image: metaImage, title, pathname  = "/", metaTitle}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            twitterUsername
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const image =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : null
  const canonical = pathname
    ? pathname === "/"
      ? `${site.siteMetadata.siteUrl}`
      : `${site.siteMetadata.siteUrl}${pathname}`
    : null

    const getTitleTemplate = (title, pathname) => {
      if (!pathname) {
        return title ? `${title}` : defaultTitle;
      }

      switch (true) {
        case pathname === "/docs":
          return `Documentation`;
    
          case pathname.startsWith("/docs"):
            return metaTitle ? ` ${metaTitle}` : ` ${title}`;    
    
        case pathname === "/blog":
          return `Odown Product News and Updates`;
    
        case pathname.startsWith("/blog/"):
          return `Odown Blog | ${title}`;
    
        default:
          return title ? `${title}` : defaultTitle;
      }
    };
    
  const titleTemplate = getTitleTemplate(title, pathname)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
 link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
          {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitterUsername,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ]
        .concat(
        metaImage
          ? [
              {
                property: "og:image",
                content: image,
              },
              {
                property: "og:image:width",
                content: metaImage.width,
              },
              {
                property: "og:image:height",
                content: metaImage.height,
              },
              {
                name: "twitter:card",
                content: "summary_large_image",
              },
            ]
          : [
              {
                name: "twitter:card",
                content: "summary",
              },
            ]
      )
      .concat(meta)}
    >
           {/* ComplyDog Cookie Widget */}
      <script type="text/javascript">
        {`(function(c,d,o,g){c.cdogSettings={campaignId:"ri3jai",version:"1.0.0"};o=d.getElementsByTagName("head")[0];g=d.createElement("script");g.async=1;g.src="https://app.complydog.com/complydog.js"+"?v="+c.cdogSettings.version;g.setAttribute("cdog-campaign", c.cdogSettings.campaignId);!c.cdogInit?o.appendChild(g):"";})(window,document);`}
      </script>

      <script type="text/plain" data-cookie-consent="functional">
        {`!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/plain",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`}
      </script>
    
      <script type="text/plain" data-cookie-consent="functional">
        {`window.Beacon('init', '20729ffa-fd0a-47a4-ac67-15c567a352a3');`}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  pathname: `/`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}

export default SEO
